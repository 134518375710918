import '../styles/Home.css'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Banner from '../assets/photos/Header with Banners.png'
import { Stack } from 'react-bootstrap'
import Episode from './Episode'
import { Nav } from 'react-bootstrap'
import ApplePodcasts from '../assets/icons/ApplePodcasts.png'
import GooglePodcasts from '../assets/icons/GooglePodcasts.png'
import Overcast from '../assets/icons/OVERCAST.png'
import Spotify from '../assets/icons/spotify.png'

const Home = () => {
    return (
        <div id='home-container'>
            <div className='banner'>
                <Image
                    src={Banner}
                    alt='Banner with Coming Soon'
                    className='banner-image'
                    fluid />
            </div>
            <Container fluid>
                <Stack>
                     {/* <Container className='title-box'>
                        <h3 className='home-title'>A Hard Day's Late Night with Ira</h3>
                    </Container> */}
                    {/*<Container className='subscribe-container'>
                        <h3 className='subscribe'>Subscribe</h3>
                        <Container className='links-box'>
                            <Stack direction='horizontal' className='hosting-links'>
                                <Stack className='hosting-link' direction='horizontal'>
                                    <Image
                                        src={ApplePodcasts}
                                    />
                                    <p className='hosting-name'>Apple Podcasts</p>
                                </Stack>
                                <Stack className='hosting-link' direction='horizontal'>
                                    <Image
                                        src={Spotify}
                                    />
                                    <p className='hosting-name'>Spotify</p>
                                </Stack>
                                <Stack className='hosting-link' direction='horizontal'>
                                    <Image
                                        src={Overcast}
                                    />
                                    <p className='hosting-name'>Overcast</p>
                                </Stack>
                                <Stack className='hosting-link' direction='horizontal'>
                                    <Image
                                        src={GooglePodcasts}
                                    />
                                    <p className='hosting-name'>Google Podcasts</p>
                                </Stack>
                            </Stack>
                        </Container>
                    </Container>
                    <Container className='socials-container'>
                        <h3 className='socials-title'>Find Us on Social Media</h3>
                        <Container className='socials-links'>
                            <Nav>
                                <Nav.Item>
                                    <Nav.Link href="https://www.instagram.com/aharddayslatenight/">
                                        <span className="fa-stack fa-lg">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fab fa-instagram fa-stack-1x"></i>
                                        </span>
                                        <p className='social-link'>Instagram</p>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="https://www.facebook.com//">
                                        <span className="fa-stack fa-lg">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fab fa-facebook fa-stack-1x"></i>
                                        </span>
                                        <p className='social-link'>Facebook</p>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Container>
                    </Container>*/}
                    <Container className='description-box'>
                        <Container className='description-text-box'>
                            <p className='description'>A Hard Day’s Late with Ira was born of debauchery and desperation.</p>
                            <p className='description'>Having attained international acclaim as the formidable drummer with indie 
                                rock icons Nada Surf, topping lists of the generation’s most influential musicians and eating 
                                in restaurants where the guy comes to table with the giant pepper grinder; 
                                Ira Elliot found himself at a crossroads.</p>
                            <p className='description'>For three decades he had traveled the world, playing five-hour-a-night
                                 marathon shows, fending off legions of horned-up and gender-inconsistent groupies, 
                                 indulging in an array of incompatible drugs and sleeping restfully ten to twelve hours a 
                                 night while looking good doing it.</p>
                            <p className='description'>But what did it all mean? What was there possibly left to achieve?</p>
                            <p className='description'>Oh, sure he could have easily returned to his first love, orthodonture.
                                 No one can attach an incisor bracket firmer or faster. But where was the glory?
                                  Where was the danger? Where was the cash money at?</p>
                            <p className='description'>And the answer was suddenly plain as day:  <i>podcasting</i>.</p>
                            <p className='description'>And so, combining his long-held dream of being a late night talk show host like 
                                Pat Sajak and Chevy Chase, with his unremitting love of the Beatles like Pete Best and Billy 
                                Preston, he turned his steady, Lasik-corrected, monovision gaze to the internets 
                                and A Hard Day’s Late Night with Ira is the result.</p>
                            <p className='description'>Now you too can bask in the questionable intelligence and half-baked 
                                wisdom of the man that Modern Drummer magazine once called “that guy who just won’t stop 
                                texting us in the middle of the night.” The court documents are available to prove it.</p>
                        </Container>
                    </Container>
                    {/* <Container className='episodes-box'>
                        <Stack gap='3'>
                            <Episode />
                            <Episode />
                            <Episode />
                        </Stack>
                    </Container>  */}
                    <h1 className='coming-soon' >Coming Soon!</h1>
                </Stack>
            </Container>
        </div>
    )
}

export default Home