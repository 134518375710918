import { Container } from "react-bootstrap"
import '../styles/Header.css'

const Header = () => {
    return (
        <Container className="header-bar" fluid>
            <h1 className="header-text text-center">Coming Soon!</h1>
        </Container>
    )
}

export default Header